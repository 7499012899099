import {
  Callback,
  ClientToServerEvents,
  ConsentViewerClosePayload,
  ConsentViewerPayload,
  DataCallback,
  EmitEventNames,
  EmitEventPayloads,
  EventSubscription,
  handleRoomReplayEvents,
  HeartbeatCallbackParams,
  Media,
  MultiEventSubscription,
  OnEventNames,
  OrganizerPresentationStateChangePayload,
  Participant,
  Pointer,
  RejectConsentPayload,
  RevokeConsentPayload,
  Room,
  RoomJoinCallbackParams,
  RoomParticipantPayload,
  RoomPayload,
  SampleRequestViewerPayload,
  ScreenShareStartPayload,
  ServerToClientEvents,
  unpackNodeId,
} from '@ysura/common';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { io, Socket } from 'socket.io-client';

import { useNotification } from '@/hooks';
import { getRealm, getStateServerUrl } from '@/utils';

import {
  DEFAULT_CALLBACK,
  DEFAULT_FN,
  InitChannelArgs,
  SetState,
} from './useInteractionTypes';

type StateServerSocket = Socket<ServerToClientEvents, ClientToServerEvents>;

export type UseStateServer = {
  isStateServerInitialized: boolean;
  totalPeopleInRoom: number;
  roomInfo?: Room;
  setRoomInfo: SetState<Room | undefined>;

  initChannel: ({ roomId }: InitChannelArgs) => void;
  doHeartbeat: (
    participant: Participant,
    callback: DataCallback<HeartbeatCallbackParams>
  ) => void;

  onAttendeeReadyOrganizerNotify: (callback: Callback) => Callback;
  onOrganizerTerminateSession: (callback: Callback) => Callback;
  onPointerShown: (callback: DataCallback<Pointer>) => Callback;
  //Sample request collection
  broadcastSampleRequestValueChange: (
    event: SampleRequestViewerPayload
  ) => void;
  broadcastSampleRequestSubmit: (event: SampleRequestViewerPayload) => void;
  onSampleRequestOpened: (
    callback: DataCallback<SampleRequestViewerPayload>
  ) => Callback;
  onSampleRequestClosed: (callback: Callback) => Callback;
  onSampleRequestValueChanged: (
    callback: DataCallback<SampleRequestViewerPayload>
  ) => Callback;
  //Consent form collection
  broadcastConsentFormValueChange: (event: ConsentViewerPayload) => void;
  broadcastConsentFormSubmit: (event: ConsentViewerPayload) => void;
  broadcastConsentClose: (event: ConsentViewerClosePayload) => void;
  onConsentFormOpened: (
    callback: DataCallback<ConsentViewerPayload>
  ) => Callback;
  onConsentClosed: (callback: Callback) => Callback;
  onOrganizerConsentRejected: (
    callback: DataCallback<RejectConsentPayload>
  ) => Callback;
  onOrganizerConsentRevoked: (
    callback: DataCallback<RevokeConsentPayload>
  ) => Callback;
  onConsentFormValueChanged: (
    callback: DataCallback<ConsentViewerPayload>
  ) => Callback;
  //Media
  onMediaOpened: (callback: DataCallback<Media>) => Callback;
  onMediaClosed: (callback: Callback) => Callback;
  onMediaStateChanged: (
    callback: DataCallback<OrganizerPresentationStateChangePayload>
  ) => Callback;
  broadcastMediaAttendeeReady: (event: Media) => void;
  broadcastPointerShow: (event: Pointer) => void;
  broadcastRejectConsentConfirm: (event: RejectConsentPayload) => void;
  broadcastRevokeConsentConfirm: (event: RevokeConsentPayload) => void;
  broadcastWaitingRoomToInteraction: () => void;

  startScreenShare: (
    payload: {
      shouldBroadcast?: boolean;
      participantSharingId?: string;
    },
    callback: (params: { token: string }) => void
  ) => void;
  stopScreenShare: () => void;
  onScreenShareStarted: (callback: (attendeeOid?: string) => void) => Callback;

  closeStateServerConnection: () => void;
};

const StateServerContext = createContext<UseStateServer>({
  isStateServerInitialized: false,
  totalPeopleInRoom: 0,
  setRoomInfo: DEFAULT_FN,
  initChannel: DEFAULT_FN,
  doHeartbeat: DEFAULT_FN,
  onAttendeeReadyOrganizerNotify: DEFAULT_CALLBACK,
  onOrganizerTerminateSession: DEFAULT_CALLBACK,
  onPointerShown: DEFAULT_CALLBACK,
  broadcastSampleRequestValueChange: DEFAULT_FN,
  broadcastSampleRequestSubmit: DEFAULT_FN,
  onSampleRequestOpened: DEFAULT_CALLBACK,
  onSampleRequestClosed: DEFAULT_CALLBACK,
  onSampleRequestValueChanged: DEFAULT_CALLBACK,
  broadcastConsentFormValueChange: DEFAULT_FN,
  broadcastConsentFormSubmit: DEFAULT_FN,
  onConsentFormOpened: DEFAULT_CALLBACK,
  onConsentClosed: DEFAULT_CALLBACK,
  onConsentFormValueChanged: DEFAULT_CALLBACK,
  onMediaOpened: DEFAULT_CALLBACK,
  onMediaClosed: DEFAULT_CALLBACK,
  onMediaStateChanged: DEFAULT_CALLBACK,
  broadcastMediaAttendeeReady: DEFAULT_FN,
  broadcastPointerShow: DEFAULT_FN,
  broadcastWaitingRoomToInteraction: DEFAULT_FN,
  startScreenShare: DEFAULT_FN,
  stopScreenShare: DEFAULT_FN,
  onScreenShareStarted: DEFAULT_CALLBACK,
  closeStateServerConnection: DEFAULT_FN,
  onOrganizerConsentRejected: DEFAULT_CALLBACK,
  onOrganizerConsentRevoked: DEFAULT_CALLBACK,
  broadcastRejectConsentConfirm: DEFAULT_FN,
  broadcastRevokeConsentConfirm: DEFAULT_FN,
  broadcastConsentClose: DEFAULT_FN,
});

export const useStateServer = (): UseStateServer => {
  return useContext(StateServerContext);
};

export const StateServerContextProvider: FC = (props) => {
  const [totalPeopleInRoom, setTotalPeopleInRoom] = useState(0);
  const socket = useRef<StateServerSocket | null>(null);
  const [roomInfo, setRoomInfo] = useState<Room | undefined>(undefined);
  const [isStateServerInitialized, setIsStateServerInitialized] =
    useState(false);

  const { toast } = useNotification();
  const { t } = useTranslation();

  // cleanup listeners & disconnect
  const disconnectSocket = useCallback(() => {
    socket.current?.removeAllListeners();
    socket.current?.disconnect();
  }, []);

  const updateRoom = useCallback((roomInfo: Room) => {
    setRoomInfo(roomInfo);

    const numberOfParticipants = roomInfo?.participants
      ? Object.keys(roomInfo.participants).length
      : undefined;

    setTotalPeopleInRoom((current) => {
      console.log('setPeopleInRoom', numberOfParticipants ?? current);

      return numberOfParticipants ?? current;
    });
  }, []);

  const initChannel = useCallback(
    ({
      roomId,
      attendee,
      hasVideoConnection,
      onTokenCreated,
      onMediaEventReplay,
      onConsentEventReplay,
      onScreenSharingEventReplay,
      onSampleRequestEventReplay,
      interactionStep,
    }: InitChannelArgs): void => {
      if (isStateServerInitialized) {
        return;
      }

      const roomJoinCallback = ({ room, token }: RoomJoinCallbackParams) => {
        updateRoom(room);

        if (token && hasVideoConnection) {
          onTokenCreated?.(token, room, attendee.personOid);
        }

        // replay events for the case where the attendee refreshed the page
        handleRoomReplayEvents(room.events, {
          onMediaEventReplay,
          onConsentEventReplay,
          onScreenSharingEventReplay,
          onSampleRequestEventReplay,
        });
      };

      const soc: StateServerSocket = io(getStateServerUrl(), {
        transports: ['websocket'],
        upgrade: false,
      });

      soc.on('room_created', (payload: RoomPayload) => {
        const { room } = payload;

        updateRoom(room);
      });

      soc.on('participant_state_changed', (payload: RoomParticipantPayload) => {
        const { room, participant: changedParticipant, stateChange } = payload;

        updateRoom(room);

        if (changedParticipant.id !== attendee.personOid) {
          const displayName = changedParticipant?.displayName;

          if (stateChange === 'connected') {
            if (changedParticipant.step === 'interaction') {
              toast?.({
                message: t('notifications.participantJoined', {
                  displayName,
                }),
                type: 'success',
              });
            } else if (changedParticipant.step === 'waiting-room') {
              toast?.({
                message: t('notifications.participantJoinedWaitingRoom', {
                  displayName,
                }),
                type: 'success',
              });
            }
          } else if (stateChange === 'waiting-room-to-interaction') {
            toast?.({
              message: t('notifications.participantJoined', {
                displayName,
              }),
              type: 'success',
            });
          } else if (stateChange === 'disconnected') {
            toast?.({
              message: t('notifications.participantLeft', {
                displayName,
              }),
              type: 'warning',
            });
          }
        }
      });

      soc.on('connect_error', () => {
        console.log('showErrorNotification(Cannot connect to state-server)');
      });

      soc.on('connect', () => {
        soc.emit(
          'room_join',
          {
            roomId: unpackNodeId(roomId),
            realm: getRealm(),
            participant: {
              id: attendee.personOid,
              displayName: attendee.displayName,
              role: 'attendee',
              step: interactionStep,
            },
            restricted: false,
            hasVideoConnection,
          },
          roomJoinCallback
        );
      });

      setIsStateServerInitialized(true);
      socket.current = soc;
    },
    [isStateServerInitialized, t, toast, updateRoom]
  );

  const doHeartbeat = useCallback(
    (
      participant: Participant,
      callback: DataCallback<HeartbeatCallbackParams>
    ) => {
      socket.current?.emit('heartbeat', { participant }, callback);
    },
    []
  );

  const subscribeToEvent = useCallback(
    ({ name, callback }: EventSubscription): Callback => {
      socket.current?.on(name, callback);

      return (): void => {
        socket.current?.removeListener(name, callback);
      };
    },
    []
  );

  const subscribeToMultipleEvents = useCallback(
    ({ names, callback }: MultiEventSubscription): Callback => {
      const callbacks: Array<Callback> = [];
      names.forEach((name: OnEventNames) => {
        const innerCallback = subscribeToEvent({ name, callback });
        callbacks.push(innerCallback);
      });

      return (): void => {
        callbacks.forEach((cb) => cb());
      };
    },
    [subscribeToEvent]
  );

  const emit = useCallback(
    (eventName: EmitEventNames, event?: EmitEventPayloads): void => {
      socket.current?.emit(eventName, event);
    },
    []
  );

  // <----------- EVENT SUBSCRIPTIONS ----------->

  // All of these three events are handled in the same way:
  // The attendee disconnects from the room
  const onOrganizerTerminateSession = useCallback(
    (callback: Callback): Callback => {
      return subscribeToMultipleEvents({
        names: [
          'organizer_do_client_disconnected',
          'organizer_room_closed',
          'session_expired',
        ],
        callback,
      });
    },
    [subscribeToMultipleEvents]
  );

  const onAttendeeReadyOrganizerNotify = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'attendee_ready_organizer_notified',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onSampleRequestOpened = useCallback(
    (callback: DataCallback<SampleRequestViewerPayload>): Callback => {
      socket.current?.on('organizer_sample_request_opened', callback);

      return (): void => {
        socket.current?.removeListener(
          'organizer_sample_request_opened',
          callback
        );
      };
    },
    []
  );

  const onSampleRequestClosed = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'sample_request_closed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onSampleRequestValueChanged = useCallback(
    (callback: DataCallback<SampleRequestViewerPayload>): Callback => {
      socket.current?.on('sample_request_viewer_state_changed', callback);

      return (): void => {
        socket.current?.removeListener(
          'sample_request_viewer_state_changed',
          callback
        );
      };
    },
    []
  );

  const onConsentFormOpened = useCallback(
    (callback: DataCallback<ConsentViewerPayload>): Callback => {
      socket.current?.on('organizer_consent_opened', callback);

      return (): void => {
        socket.current?.removeListener('organizer_consent_opened', callback);
      };
    },
    []
  );

  const onConsentClosed = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'consent_closed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onOrganizerConsentRejected = useCallback(
    (callback: DataCallback<RejectConsentPayload>): Callback => {
      return subscribeToEvent({
        name: 'organizer_consent_rejected',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onOrganizerConsentRevoked = useCallback(
    (callback: DataCallback<RevokeConsentPayload>): Callback => {
      return subscribeToEvent({
        name: 'organizer_consent_revoked',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onConsentFormValueChanged = useCallback(
    (callback: DataCallback<ConsentViewerPayload>): Callback => {
      socket.current?.on('consent_viewer_state_changed', callback);

      return (): void => {
        socket.current?.removeListener(
          'consent_viewer_state_changed',
          callback
        );
      };
    },
    []
  );

  const onMediaOpened = useCallback(
    (callback: DataCallback<Media>): Callback => {
      socket.current?.on('organizer_media_opened', callback);

      return (): void => {
        socket.current?.removeListener('organizer_media_opened', callback);
      };
    },
    []
  );

  const onMediaClosed = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'organizer_media_closed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onMediaStateChanged = useCallback(
    (
      callback: DataCallback<OrganizerPresentationStateChangePayload>
    ): Callback => {
      socket.current?.on('organizer_presentation_state_changed', callback);

      return (): void => {
        socket.current?.removeListener(
          'organizer_presentation_state_changed',
          callback
        );
      };
    },
    []
  );

  const onPointerShown = useCallback(
    (callback: DataCallback<Pointer>): Callback => {
      socket.current?.on('pointer_shown', callback);

      return (): void => {
        socket.current?.removeListener('pointer_shown', callback);
      };
    },
    []
  );

  const onScreenShareStarted = useCallback(
    (callback: Callback) => {
      return subscribeToEvent({ name: 'screen_share_started', callback });
    },
    [subscribeToEvent]
  );

  // < ----------- EVENT BROADCASTS ----------->

  const broadcastWaitingRoomToInteraction = useCallback((): void => {
    emit('waiting_room_to_interaction_join');
  }, [emit]);

  const broadcastMediaAttendeeReady = useCallback(
    (event: Media): void => {
      emit('attendee_ready_for_media', event);
    },
    [emit]
  );

  const broadcastSampleRequestValueChange = useCallback(
    (event: SampleRequestViewerPayload) => {
      emit('sample_request_viewer_state_change', event);
    },
    [emit]
  );

  const broadcastSampleRequestSubmit = useCallback(
    (event: SampleRequestViewerPayload): void => {
      emit('attendee_sample_request_submit', event);
    },
    [emit]
  );

  const broadcastConsentFormValueChange = useCallback(
    (event: ConsentViewerPayload) => {
      emit('consent_viewer_state_change', event);
    },
    [emit]
  );

  const broadcastConsentFormSubmit = useCallback(
    (event: ConsentViewerPayload): void => {
      emit('attendee_consent_submit', event);
    },
    [emit]
  );

  const attendeeReady = useCallback(
    (event: Media): void => {
      emit('attendee_ready_for_media', event);
    },
    [emit]
  );

  const broadcastPointerShow = useCallback(
    (event: Pointer): void => {
      emit('pointer_show', event);
    },
    [emit]
  );

  const broadcastRejectConsentConfirm = useCallback(
    (event: RejectConsentPayload): void => {
      emit('attendee_consent_reject_confirm', event);
    },
    [emit]
  );

  const broadcastRevokeConsentConfirm = useCallback(
    (event: RevokeConsentPayload): void => {
      emit('attendee_consent_revoke_confirm', event);
    },
    [emit]
  );

  const broadcastConsentClose = useCallback(
    (event: ConsentViewerClosePayload) => {
      emit('consent_close', event);
    },
    [emit]
  );

  // <----------- Other Emits ----------->
  const startScreenShare = useCallback(
    (
      event: ScreenShareStartPayload,
      callback: (params: { token: string }) => void
    ) => {
      socket.current?.emit('screen_share_start', event, callback);
    },
    []
  );

  const stopScreenShare = useCallback(() => {
    emit('screen_share_stop');
  }, [emit]);

  const closeStateServerConnection = useCallback(() => {
    disconnectSocket();

    setIsStateServerInitialized(false);
    setRoomInfo(undefined);
  }, [disconnectSocket]);

  const contextValue = useMemo(
    () => ({
      isStateServerInitialized,
      totalPeopleInRoom,
      roomInfo,
      setRoomInfo,

      initChannel,
      doHeartbeat,
      attendeeReady,

      onAttendeeReadyOrganizerNotify,
      onOrganizerTerminateSession,
      onMediaStateChanged,
      onPointerShown,
      onMediaClosed,
      onMediaOpened,

      broadcastMediaAttendeeReady,
      broadcastPointerShow,
      broadcastWaitingRoomToInteraction,
      broadcastRejectConsentConfirm,
      broadcastRevokeConsentConfirm,
      broadcastConsentClose,

      broadcastSampleRequestSubmit,
      broadcastSampleRequestValueChange,
      onSampleRequestValueChanged,
      onSampleRequestOpened,
      onSampleRequestClosed,

      broadcastConsentFormValueChange,
      broadcastConsentFormSubmit,
      onConsentFormValueChanged,
      onConsentFormOpened,
      onConsentClosed,
      onOrganizerConsentRejected,
      onOrganizerConsentRevoked,

      startScreenShare,
      stopScreenShare,
      onScreenShareStarted,

      closeStateServerConnection,
    }),
    [
      isStateServerInitialized,
      totalPeopleInRoom,
      roomInfo,
      initChannel,
      doHeartbeat,
      attendeeReady,
      onAttendeeReadyOrganizerNotify,
      onOrganizerTerminateSession,
      onMediaStateChanged,
      onPointerShown,
      onMediaClosed,
      onMediaOpened,
      broadcastMediaAttendeeReady,
      broadcastPointerShow,
      broadcastWaitingRoomToInteraction,
      broadcastRejectConsentConfirm,
      broadcastRevokeConsentConfirm,
      broadcastConsentClose,

      broadcastSampleRequestSubmit,
      broadcastSampleRequestValueChange,
      onSampleRequestValueChanged,
      onSampleRequestOpened,
      onSampleRequestClosed,
      broadcastConsentFormValueChange,
      broadcastConsentFormSubmit,
      onConsentFormValueChanged,
      onConsentFormOpened,
      onConsentClosed,
      onOrganizerConsentRejected,
      onOrganizerConsentRevoked,
      startScreenShare,
      stopScreenShare,
      onScreenShareStarted,
      closeStateServerConnection,
    ]
  );

  return <StateServerContext.Provider value={contextValue} {...props} />;
};
